<template>
  <div class="tc-note-wrapper" style="padding-top: 50px">
    <div class="row panel-heading navbar-item align-items-center">
      <div class="col-md-3">
        <div class="">
          <input type="text"  @keyup="getApartmentsByName" class="form-control" placeholder="Search object..." v-model="apartmentFilter" style="background-color: white">
        </div>
      </div>
      <div class="col-md-3">
        <Multiselect style="background-color: white"
                     v-model='department'
                     mode="tags"
                     placeholder='Select department(s)...'
                     label='name'
                     trackBy="name"
                     valueProp="id"
                     :searchable='true'
                     :options='departments'
                     @change="getApartmentsByDepartments"
        >
        </Multiselect>
      </div>
      <div class="col-md-3">
        <Multiselect style="background-color: white"
                     v-model='office'
                     mode="tags"
                     placeholder='Select office(s)...'
                     label='name'
                     trackBy="name"
                     valueProp="id"
                     :searchable='true'
                     :options='departments'
                     @change="getApartmentsByOffice"
        >
        </Multiselect>
      </div>
      <div class="col-md-3">
        <Multiselect style="background-color: white"
                     v-model='owner'
                     mode="tags"
                     placeholder='Select owner(s)...'
                     label='name'
                     trackBy="name"
                     valueProp="id"
                     :searchable='true'
                     :options='owners'
                     @change="getApartmentsByOwner"
        >
        </Multiselect>
      </div>
    </div>
    <div class="main-content" style="margin-top: 80px">
<!--      <div>-->
        <div class="card-custom">
          <div id="map" />
        </div>
<!--      </div>-->
      <div class="card-custom">
        <div class="row text-center" style="padding: 8px 14px 8px 14px;">
          <div class="col-md-4">
<!--            <div class="input-group" style="float: left">-->
<!--              <a class="input-group-text" title="Create New Apartment" @click="createApartment">-->
<!--                <i class="fas fa-plus fa-lg" aria-hidden="true"></i>-->
<!--              </a>-->
<!--              <span><input type="text"  @keyup="getApartmentsByName" class="form-control custom" placeholder="Search object..." v-model="apartmentFilter"></span>-->
<!--            </div>-->
          </div>
          <div class="col-md-4" style="text-align: center"><h4>Resources list : ({{numberOfApartments}})</h4></div>
<!--          <div class="col-md-4">-->
<!--            <div class="" v-if="numberOfApartments>10" style="float: right">-->
<!--            <v-pagination-->
<!--                v-model="page"-->
<!--                :pages="pages"-->
<!--                :range-size="1"-->
<!--                active-color="#DCEDFF"-->
<!--                @update:modelValue="updateHandler"-->
<!--            />-->
<!--          </div>-->
<!--            </div>-->
        </div>
        <div class="row table-header">
          <div class="col-md-1 tc-note-body">Avatar</div>
          <div class="col-md-2 tc-note-body">Name</div>
          <div class="col-md-1 tc-note-body">Department</div>
          <div class="col-md-1 tc-note-body">Address</div>
          <div class="col-md-1 tc-note-body">Sleeping Place</div>
          <div class="col-md-1 tc-note-body">Email</div>
          <div class="col-md-1 tc-note-body">Phone</div>
          <div class="col-md-1 tc-note-body">Status</div>
          <div class="col-md-1 tc-note-body"><a @click="createWorker"><i class="fas fa-plus-circle fa-lg" style="color:red" title = 'Create New Worker'></i></a></div>
        </div>
        <div class="tc-notes">
          <div class="" v-if="workers.length>0">
            <div class="tc-note-body" style="background-color: white">

            <div class="row tc-note" v-for="(worker, index) in workers"  :key="worker.id" :index="index" @dblclick="cardWorker(element.id)">
              <div class="col-md-1 tc-note-body"><a style="color: #01818C; cursor: pointer;"><i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i></a></div>
              <div class="col-md-2 tc-note-body">{{ worker.name}}</div>
              <div class="col-md-1 tc-note-body">{{ worker.department}}</div>
              <div class="col-md-1 tc-note-body">{{ worker.address}}</div>
              <div class="col-md-1 tc-note-body">{{ worker.sleepingPlace}}</div>
              <div class="col-md-1 tc-note-body">{{ worker.email}}</div>
              <div class="col-md-1 tc-note-body">{{ worker.phone}}</div>
              <div class="col-md-1 tc-note-body">{{ worker.status}}</div>
              <div class="col-md-1">
                <a @click="apartmentInfo(apartment.id)"  title = 'View / Update' style="color: #01818C; cursor: pointer;"><i class="fas fa-eye" ></i></a>
              </div>
            </div>
            <teleport to="#modal-area">
              <div v-if="modalOpened"  class="modal-area">
                <span @click="closeModal" class="close-modal">&times;</span>
                  <div class="modal-header"><h3>Schedule settings:</h3></div>
                <div class="modal-body">
                  <div class="row text-center">
                    <div class="">
                      <label class="control-label" data-custom-for="ticketform-estimated_time">DateTime Range for schedule</label>
                      <flat-pickr v-model="range" :config="config" class="form-control" placeholder="Select Date & Time"  ></flat-pickr>
                      <p class="help-block help-block-error"></p>
                    </div>
                    <div class="">
                      <label class="control-label" data-custom-for="ticketform-estimated_time">Type of service layout</label>
                      <Multiselect
                          v-model='topicId'
                          placeholder='Select type of service...'
                          label='name'
                          trackBy='name'
                          valueProp='id'
                          :searchable='true'
                          :options='topics'
                          required=""
                      >
                      </Multiselect>
                      <p class="help-block help-block-error"></p>
                    </div>
                    <div class="modal-footer">
  <!--                    <input type="submit"  class="btn btn-warning" value="&#43; Save">-->
                      <input type="submit"  class="btn btn-warning" value="&#10011; Save">
  <!--                    <input type="submit"  class="btn btn-warning" value="&#10011; Save">-->
  <!--                    <input type="submit"  class="btn btn-warning" value="&#10007; Cancel">-->
  <!--                    <input type="submit"  class="btn btn-warning" value="&#10003; Create">-->
  <!--                    <input type="submit"  class="btn btn-warning" value="&#10001; Update">-->
  <!--                    <input type="submit"  class="btn btn-warning" value="&#x2704; Clear">-->
                    </div>

                  </div>
<!--                  <div style="padding-bottom: 10px">-->
<!--                    <button type="button" class="btn btn-warning" @click="addSchedule"><i class="fas fa-plus"></i>  Save</button>-->
<!--                  </div>-->
                </div>
              </div>
            </teleport>
            </div>
          </div>
          <div class="row" v-else>There is no one apartment</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "@/store";
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import {ActionTypes} from "@/store/actions";
import {MutationType} from "@/store/mutations";
import router from "@/router";
//import VPagination from "vue3-pagination";
//import "vue3-pagination/dist/vue3-pagination.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
import Multiselect from '@vueform/multiselect'

export default {
name: "WorkerList",
  setup() {
    const store = useStore();
    const modalOpened = computed(() => store.getters.modalOpened);
    const topics = computed(() => store.getters.topics);
    const user = computed( () => store.getters.getUser);
    const workers = computed(() => store.getters.resources);
    const allApartments = computed( () => store.getters.apartments);
    const apartments = computed( () => store.getters.filteredApartments);
    const numberOfApartments = computed( () => store.getters.apartments.length);
    const numberOfWorkers = computed( () => store.getters.workers.length);
    let department =  null;// "Housekeeping Pag";
    let office =  null;// "Housekeeping Pag";
    const apartmentFilter =  ref("");// "Housekeeping Pag";
    const page =  ref("");// "Housekeeping Pag";
    const pages =  ref(10);// "Housekeeping Pag";
    const departments = computed(() => store.getters.departments);
    const scheduleId = ref("");
    const lat = ref(null);
    const lon = ref(null);
    const addressFromMap = ref("");
    const choosedApartmentId = ref(null);
    const choosedSchedule = reactive ({
      topic: "",
      range: "",
      topicId: "",
      apartmentId: "",
      id:"",
      config : {
        enableTime: true,
        mode: "range",
        dateFormat: "d.m.Y H:i",
        time24hr: true,
        minTime: "8:00",
        maxTime: "22:00",
        wrap: true, // set wrap to true only when using 'input-group'
        defaultDate: [],
      },
    })
    const getApartmentsByDepartments = ((event) => {
      department = event
      store.commit(MutationType.setCurrentDepartment,event);
    })
    const scheduleMe = ((id) => {
      store.commit(MutationType.setCurrentDepartment,event);
    })

    const getApartmentsByName = (() => {
      store.commit(MutationType.setApartmentNameFilter, apartmentFilter.value);
    })
    const updateHandler = ((event) => {
      page.value = event
      store.commit(MutationType.setCurrentPage, page.value);
    })
    const getApartmentsByOffice = ((event) => {
      office = event
      store.commit(MutationType.setCurrentOffice,event);
    })
    const createApartment = () => {
      router.push( {name: 'apartment/new' })
    }
    const apartmentInfo = (id) => {
      router.push( {name: 'apartment', params: { id: id} })
    }
    const closeModal = () =>{
      scheduleId.value = null
      scheduleId.value =""
      choosedSchedule.topic = ""
      choosedSchedule.topicId = ""
      choosedSchedule.apartmentId = ""
      choosedSchedule.range = ""
      choosedSchedule.id = ""
      choosedSchedule.config.defaultDate.forEach((date,index) => {
        choosedSchedule.config.defaultDate.splice(index,1)
      })
      store.commit(MutationType.changeModal,false);
    };
    const openModal = (schedule) =>{

      if (schedule !== null && schedule !== undefined) {
        scheduleId.value = schedule.id
        choosedSchedule.topic = schedule.topic
        choosedSchedule.apartmentId = schedule.apartment_id
        choosedSchedule.topicId = schedule.topic_id
        choosedSchedule.range = schedule.range
        choosedSchedule.id = schedule.id
        choosedSchedule.config.defaultDate.push(new Date(schedule.from * 1000))
        choosedSchedule.config.defaultDate.push(new Date(schedule.to * 1000))
      }
      else {
        choosedSchedule.config.defaultDate.push(new Date())
        choosedSchedule.config.defaultDate.push(new Date('+1 year'))
      }
      store.commit(MutationType.changeModal, true);
    };

    const createWorker = () => {
      router.push( {name: 'worker/new' })
    }
    const cardWorker = (id) => {
      router.push( {name: 'card', params: { id: id} })
    }
    onMounted(async () => {
      console.log('component mounted!');
      await store.dispatch(ActionTypes.getUser);
      await store.dispatch(ActionTypes.getDepartments);
      await store.dispatch(ActionTypes.fetchTopics);
      await store.dispatch(ActionTypes.getWorkers,374);
      await store.dispatch(ActionTypes.fetchApartments).then(()=> {
       // pages.value = Math.ceil(numberOfApartments.value/store.pageSize)
      });
     // pages.value = Math.ceil(numberOfApartments.value/store.pageSize)

      mapboxgl.accessToken = "pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw";

      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [14.2873, 45.3148],
        zoom: 8
      });

      map.on('load', () => {
        map.scrollZoom.enable();
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.ScaleControl({position: 'bottom-left'}));
        const marker = new mapboxgl.Marker({draggable: false})
            .setLngLat([14.2873, 45.3148])
            .setPopup(new mapboxgl.Popup({ offset: 25 })
                .setHTML('<div>Rona Central Reception</div>'))
            .addTo(map);

        allApartments.value.forEach((apartment) => {
          const popup = new mapboxgl.Popup({ offset: 25 })
              .setHTML('<div id="'+apartment.id+'">'+apartment.name+'</div>');
          popup.on('open', function(ev) {
            lat.value = ev.target.getLngLat().lat;
            lon.value = ev.target.getLngLat().lng;
            addressFromMap.value = apartment.address;
            choosedApartmentId.value = apartment.id;
          });
          const appMarker = new mapboxgl.Marker({draggable: false}).setLngLat([apartment.longitude, apartment.latitude])
              .setPopup(popup)
              .addTo(map);
        })
      })
    })
    return {store, workers, createWorker, cardWorker ,allApartments,
      departments,apartments,department,office,user, getApartmentsByDepartments, apartmentFilter,
      getApartmentsByOffice, getApartmentsByName,createApartment,updateHandler,numberOfApartments, numberOfWorkers, page,pages,
      lat, lon, addressFromMap, choosedApartmentId, apartmentInfo, modalOpened, openModal, closeModal, topics,...toRefs(choosedSchedule)
    }
  },
  components: {
    // VPagination,
    flatPickr, Multiselect
  },

}
</script>

<style scoped>
#map {
  position: relative;
  width: 100%;
  top: 0;
  bottom: 0;
  min-height: 300px;
  height: 300px;
}
</style>

<style lang="scss" scoped>
.actions-icon {
  font-size: 15px; color: #01818C; margin-right: 8px;
  cursor: pointer;
}
.disabledButton {
  opacity: 0.3;
  cursor: not-allowed;
}
.closed {
  opacity: 0.5; /* Полупрозрачность элемента */
}
.deleted {
  /*background-color: #EFF6F7;*/
  text-decoration: line-through;
  opacity: 0.3; /* Полупрозрачность элемента */
}
.processing {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(3, 128, 140, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(3, 128, 140, 0.6);
  border: 0!important;
}
.ticket-on-processing {
  background-color:   #ddecee;
  opacity:   1;
}
.ticket-on-pause {
  background-color:  #e6e6e6;
  opacity:   0.8;
}
.ticket-stop {
  background-color:  whitesmoke;
  opacity:   1;
}
.ticket-finished {
  background-color:  	#fdf4e8;
  opacity:   0.8;
}
.ticket-closed {
  background-color:  	 #999999;
  opacity:   0.8;
}
.ticket-deleted {
  opacity:   0.8;
  text-decoration: line-through;
}
.out_of_schedule {
  background-color: red;
  opacity: 0.8;
}


.tc-notes-wrapper {
  padding-top: 30px;
  .tc-notes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

.table-header {
  padding: 8px 0px 18px 0px; color: #f26c0d; font-weight: bolder; background-color: whitesmoke;
  margin: 0px -1px -10px -1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tc-note {
  //background-color: #f0c806;
  border-radius: 8px;
  width: 100%;
  margin: 0 0 1px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  //cursor: pointer;
  font-family: 'Caveat', cursive;

  .tc-note-body {
    //font-size: 20px;
    padding: 5px 0;
  }

  &:hover {
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  }
}

</style>
